import React, { useState, useEffect } from "react";
import { directoryInfoService } from "../../services/directoryService.service";
import { categoryServiceService } from "../../services/categoryService.service";
import { metaPixelService } from "../../services/metaPixel.service";
import { backendService } from "../../services/backend.service";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import { ItemDirectory } from "./itemDirectory";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./directory.scss";

export function Directory() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };

  const trigger = undefined;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [directoryInfo, setDirectoryInfo] = useState([]);
  const [directoryInfoToAuthorize, setdirectoryInfoToAuthorize] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [profile, setProfileInfo] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({
    selectLocation: "",
    selectCategory: 0,
    selectSubCategory: 0,
    txtFilter: "",
  });
  const [filteredDirectory, setFilteredDirectory] = useState([]);

  const getProfileInfo = () => {
    let id = localStorage["userId"];
    setProfileInfo(id);
  };

  const handleSelectFilter = (event) => {
    if ([event.target.name] == "selectCategory") {
      if (event.target.value != "0") {
        categoryServiceService
          .getSubCategoriesFilterByCategory(
            event.target.value,
            process.env.REACT_APP_COMPANY
          )
          .then((subs) => {
            setSubCategoryFilter(subs);
          });
      } else {
        setSubCategoryFilter([]);
      }
    }
    setSelectedFilter({
      ...selectedFilter,
      [event.target.name]: event.target.value,
    });
  };
  const getInitialValues = (roleName) => {
    getListos();
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    setIsAdmin(() => {
      return roleName == "Recursos Humanos";
    });
    if (roleName == "Recursos Humanos")
      getPending();
  }, [roleName]);

  useEffect(() => {
    //if (roleName == "Recursos Humanos") setIsAdmin(true)
    getInitialValues(roleName);
  }, [trigger]);

  const getListos=()=> {
    directoryInfoService
      .getAllByCompany(process.env.REACT_APP_COMPANY)
      .then((data) => {
        if (data != null) {
          setDirectoryInfo(data);
          setFilteredDirectory(data);
          const locations = [...new Set(data.map((data) => data.location))];
          setLocationFilter(locations);
          categoryServiceService
            .getCategoryCatalogFilterByCompany(process.env.REACT_APP_COMPANY)
            .then((categories) => {
              if (categories != null) {
                setCategoryFilter(categories);
              }
            });
        }
      })
      .catch((message) => { });
  }

  const getPending = () => {
    directoryInfoService
      .getAllByCompany(process.env.REACT_APP_COMPANY, 1)
      .then((dataToAuth) => {
        setdirectoryInfoToAuthorize(dataToAuth);
      });
  }

  useEffect(() => {
    updateFilterResult(directoryInfo);
    setSearchTerm(selectedFilter.txtFilter);
  }, [selectedFilter]);

  const updateFilterResult = (directoryInfo) => {
    let result = directoryInfo;
    if (selectedFilter.selectLocation) {
      setIsFiltered(true);
      result = directoryInfo.filter((directory) => {
        return directory.location == selectedFilter.selectLocation;
      });
    }
    if (
      selectedFilter.selectCategory != 0 &&
      selectedFilter.selectCategory != "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return directory.categoryServiceId == selectedFilter.selectCategory;
      });
    }

    if (
      selectedFilter.selectSubCategory != 0 &&
      selectedFilter.selectSubCategory != "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return (
          directory.subCategoryServiceId == selectedFilter.selectSubCategory
        );
      });
    }

    if (selectedFilter.txtFilter.length >= 2) {
      let word = selectedFilter.txtFilter
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setIsFiltered(true);

      result = result.filter((directory) => {
        return (
          directory.fullName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.location
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.subCategoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.categoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          (directory.directoryContacts.filter((x) => x.contactTypeId == 1)
            .length > 0
            ? directory.directoryContacts
              .filter((x) => x.contactTypeId == 1)[0]
              .contactValue.toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(word)
            : false) || //Telefono
          (directory.directoryContacts.filter((x) => x.contactTypeId == 2)
            .length > 0
            ? directory.directoryContacts
              .filter((x) => x.contactTypeId == 2)[0]
              .contactValue.toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(word)
            : false)
        ); //Email
      });
    }

    setFilteredDirectory(result);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      let word = searchTerm
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setIsFiltered(true);
      if (searchTerm.length >= 5) {
        var pixelData = { content_category: "Directorio", search_string: word };
        metaPixelService.trackEvent("track", "Search", pixelData);
      }
    }, 2345);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const cleanFilter = () => {
    setIsFiltered(false);
    setSelectedFilter({
      selectLocation: "",
      selectCategory: 0,
      selectSubCategory: 0,
      txtFilter: "",
    });
  };

  return (
    <section className="wrapper_directory padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Anuncios</h2>
          <div className="wrp_filter_directory">
            <Link className="btn primary" to="/register-services">
              Publica tus anuncios
            </Link>
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">El servicio que necesitas</span>
          </div>
          {isAdmin ? (
            <div className="box_options_admin">
              <span className="linkbn" onClick={fSectionOn}>
                <i className="ri-arrow-left-right-line"></i>
                {sectionOn && directoryInfoToAuthorize
                  ? ` Por autorizar (${directoryInfoToAuthorize.length})`
                  : " Autorizados"}
              </span>
            </div>
          ) : (
            ""
          )}
        </h4>
      </div>
      {sectionOn ? (
        <div className="box_filter_messg">
          <div className=" box_ordering">
            <div className="ttls_order">Ubicación:</div>
            <select
              id="selectLocation"
              name="selectLocation"
              className="form-control form-control-sm"
              onChange={handleSelectFilter}
              value={selectedFilter.selectLocation}
            >
              <option value={""} defaultValue>
                Todas
              </option>
              {locationFilter &&
                locationFilter.map((locationItem) => (
                  <option key={locationItem} value={locationItem}>
                    {locationItem}
                  </option>
                ))}
            </select>
          </div>
          <div className=" box_ordering">
            <div className="ttls_order">Categorías:</div>
            <select
              id="selectCategory"
              name="selectCategory"
              className="form-control form-control-sm"
              onChange={handleSelectFilter}
              value={selectedFilter.selectCategory}
            >
              <option value={0} defaultValue>
                Todas
              </option>
              {categoryFilter &&
                categoryFilter.map((categoryItem) => (
                  <option
                    key={categoryItem.categoryServiceId}
                    value={categoryItem.categoryServiceId}
                  >
                    {categoryItem.categoryName}
                  </option>
                ))}
            </select>
          </div>
          <div className=" box_ordering">
            <div className="ttls_order">Subcategorías:</div>
            <select
              id="selectSubCategory"
              name="selectSubCategory"
              className="form-control form-control-sm"
              onChange={handleSelectFilter}
              value={selectedFilter.selectSubCategory}
            >
              <option value={0} defaultValue>
                Todas
              </option>
              {subCategoryFilter &&
                subCategoryFilter.map((subCategoryItem) => (
                  <option
                    key={subCategoryItem.SubCategoryServiceId}
                    value={subCategoryItem.subCategoryServiceId}
                  >
                    {subCategoryItem.subCategoryName}
                  </option>
                ))}
            </select>
          </div>
          <div className=" box_ordering">
            <div className="ttls_order">Busqueda:</div>
            <input
              type="text"
              placeholder="Búsqueda"
              name="txtFilter"
              id="txtFilter"
              className="form-control-sm"
              onChange={handleSelectFilter}
              value={selectedFilter.txtFilter}
            ></input>
          </div>
          {isFiltered ? (
            <span className="btn_clean_filter" onClick={cleanFilter}>
              LIMPIAR FILTROS
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {sectionOn ? (
        <div className="row">
          {filteredDirectory.map((item) => (
            <ItemDirectory
            key={item.directoryInfoId}
              directoryItem={item}
              isAdmin={isAdmin}
              sectionOn={sectionOn}
              profileId={profile}
            />
          ))}
        </div>
      ) : //""
        isAdmin ? (
          <div className="row">
            {directoryInfoToAuthorize.map((toAuthorize) => (
              <ItemDirectory
                directoryItem={toAuthorize}
                isAdmin={isAdmin}
                setdirectoryInfoToAuthorize={setdirectoryInfoToAuthorize}
                profileId={profile}
                sectionOn={sectionOn}
                updateListP={getPending}
                updateListA={getListos}
              />
            ))}
          </div>
        ) : (
          ""
        )}
    </section>
  );
}
