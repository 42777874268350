import React, {useEffect, useState} from "react";
import { useParams} from "react-router-dom";
import "./jobBank.scss";
import { postulanteService } from "../../services/postulantJob.service";
import Swal from "sweetalert2";
import {Alert} from "reactstrap";
import { externalPostulantService } from "../../services/externalPostulantJob.service";

export function OpenApplicantForm({ closeForm, job, user }) {
  const [valMessage, setValMessage] = useState("");
  const [fileValidator, setFileValidator] = useState(false);
  const [archivo, setArchivo] = useState({
    archivo: null,
    archivoNombre: "",
    archivoURL: "",
  });
  const [applicant,setApplicant] = useState({
    fullName:'',
    email: '',
    phoneNumber: '',
    zipCode: '',
    city: '',
    state: ''
  });
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState(false);

  let { id } = useParams();

  const onSave = (e) => {
    if (validateFile(e.target.files[0])) {
      setArchivo({
        archivo: e.target.files[0],
        archivoNombre: e.target.files[0].name,
      });
      setFileValidator(false);
    } else {
      setFileValidator(true);
    }
  };

  const showErrors = (msg, bool) => {
    setShowError(bool);
    setMsgError(msg);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      applicant.fullName === "" ||
      applicant.email === "" ||
      applicant.neighboor === "" ||
      applicant.zipcode === "" ||
      applicant.phone === "" ||
      applicant.city === ""
    ) {
      showErrors("Falta algún campo por llenar, revisa por favor.", true);
      return;
    }

    const regexOnlyNumber = /^[0-9]*$/;
    const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!regexEmail.test(applicant.email)) {
      showErrors("El correo no tiene el formato correcto", true);
      return;
    }

    if (applicant.phone || applicant.zipcode) {
      if (
        !regexOnlyNumber.test(applicant.phone) ||
        !regexOnlyNumber.test(applicant.zipcode)
      ) {
        showErrors(
          "Revisa los campos de teléfono o C.P., solo se permiten números",
          true
        );
        return;
      }
    }

    const formdata = new FormData();
    if (archivo.archivo === null) {
      formdata.append("Curriculum", null);
    } else {
      formdata.append("Curriculum", archivo.archivo);
    }

    formdata.append("JobId", id);

    postulanteService.agregarPostulante(formdata).then((result) => {
      if (result?.responseCode === "00") {
        //corregir lo de mensajes y mandar el correo.
        Swal.fire({
          title: "¡Se envió tu postulación!",
          text: "Estamos evaluando tu solicitud, si cumples con el perfil nos estaremos contactando contigo.",
          icon: "success",
          allowOutsideClick: false,
        }).then(() => closeForm());
      } else {
        Swal.fire({
          title: "Error al postularse",
          text: result.responseMessage,
          icon: "error",
          allowOutsideClick: false,
        }).then(() => {
          closeForm();
        });
      }
    });
  };

  const getFile = async (url) => {
    if (url === null) return;
    let barra = url.lastIndexOf("/");
    let nombre = url.substring(barra + 1);

    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], nombre);
    let container = new DataTransfer();
    container.items.add(file);
    document.querySelector("#archivo").files = container.files;

    setArchivo({
      archivo: file,
      archivoNombre: nombre,
    });
  };

  const imageExt = ["pdf", "doc", "docx"];

  const validateFile = (fileData) => {
    var re = /(?:\.([^.]+))?$/;
    if (fileData.size > 10000000) {
      setValMessage("El tamaño debe ser menor a 10MB");
      return false;
    }
    var ext = re.exec(fileData.name)[1];
    if (imageExt.includes(ext)) {
      setValMessage("");
      return true;
    } else {
      setValMessage("Formato no válido");
      return false;
    }
  };

  const handleInputValue = (e) => {
    const { value, name } = e.target;
    setApplicant({ ...applicant, [name]: value });
  };
  console.log(applicant);

  return (
    <div className="wrapper_applicant_form">
      <div className="content_applicant_form">
        <div>
          <button className="goback trans" onClick={() => closeForm()}>
            <span className="ml-1">
              <ion-icon name="chevron-back-outline"></ion-icon> Regresar
            </span>
          </button>
        </div>
        <div className="sec_form_applicant card_shadows">
          <div className="content_application">
            <div className="info_business_applicant">
              <h6 className="text fnt_medium mb-1">{job.title}</h6>
              <p>
                {job.companyName} - {job.location}
              </p>
            </div>
            <form className="info_user_applicant">
              {/* {user &&  */}
              <fieldset style={{ padding: ".5rem" }}>
                <legend className="mb-0">Información del postulante</legend>
                {showError && (
                  <Alert color="danger">
                    <small>¡Cuidado!, {msgError}</small>
                  </Alert>
                )}
                <div className={"row mx-0"}>
                  <div className={"col-12"}>
                    <div className="form-group">
                      <label htmlFor="fullName">Nombre completo</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Escribe tu nombre completo"
                        name="fullName"
                        onChange={handleInputValue}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-12"}>
                    <div className="form-group">
                      <label htmlFor="email">Correo electrónico</label>
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        placeholder="correo@dominio.com"
                        name="email"
                        onChange={handleInputValue}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-6 col-sm-6 col-xs-12"}>
                    <div className="form-group">
                      <label htmlFor="phone">Teléfono</label>
                      <input
                        type="text"
                        maxLength={10}
                        className="form-control form-control-sm"
                        placeholder="00-00-00-00-00"
                        name="phone"
                        onChange={handleInputValue}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-6 col-sm-6 col-xs-12"}>
                    <div className="form-group">
                      <label htmlFor="zipcode">C.P.</label>
                      <input
                        type="text"
                        maxLength={5}
                        className="form-control form-control-sm"
                        placeholder=""
                        name="zipcode"
                        onChange={handleInputValue}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-6"}>
                    <div className="form-group">
                      <label htmlFor="email">Ciudad</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=""
                        name="city"
                        required={true}
                        onChange={handleInputValue}
                      />
                    </div>
                  </div>
                  <div className={"col-md-6"}>
                    <div className="form-group">
                      <label htmlFor="neighboor">Colonia</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=""
                        name="neighboor"
                        onChange={handleInputValue}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              {/* } */}
              <div className="mt-4">
                <p className="mb-1">
                  Adjunta tu currículum <i>(Formato .pdf)</i>
                </p>
                {fileValidator && (
                  <p className="mb-1 text-danger">{valMessage}</p>
                )}
                <div className="custom-file">
                  <input
                    type="file"
                    className="file-upload"
                    id="archivo"
                    lang="es"
                    name="archivo"
                    accept=".pdf"
                    onChange={(x) => onSave(x)}
                  />
                  {archivo.archivoNombre === "" ? (
                    <label className="custom-file-label" htmlFor="archivo">
                      Seleccionar Archivo
                    </label>
                  ) : (
                    <label className="custom-file-label" htmlFor="archivo">
                      {archivo.archivoNombre}
                    </label>
                  )}
                  {/* <label className="custom-file-label" for="archivo">
                    Seleccionar Archivo
                  </label> */}
                </div>
              </div>
              <div className="text-right mt-4">
                {/* to="/applicant-sent" */}
                <button className="btn primary" onClick={onSubmit}>
                  Continuar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
